<template>
  <div class="content-box vision-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle='高度近视检查' @back="$router.go(-1)">
    </v-Header> -->
    <div v-wechat-title="'高度近视检查'"></div>
    <div class="page-content">
      <div class="vision-box-content">
        <div class="flex flex-align-items vision-title-block" style="justify-content: space-between">
          <div  style="position: relative">
            <audio id="myAudio" hidden="true" controls ref="myAudio" @ended="overAudio">
              <source :src="playSrc" type="audio/mpeg" />
            </audio>
            <!-- <img v-if="!isPlay" src="../../assets/imgs/c.png" alt="" style="width:24px" /> -->
            <div v-if="isPlay" class="mountain"></div>
          </div>
          <div>
            <img src="../../assets/imgs/eye.png" alt="" />{{
                $store.state.eyeText
            }}
          </div>
          <div class="align-right">
            <!-- <span class='right-word' @click="$router.push({ name: 'index' })">低视力检查</span> -->
          </div>
        </div>
        <div class="vision-content">
          <!-- 第{{ ins + 1 }}行 -->
          <ul class="list">
            <div id="echart" class="divc">
              <li v-for="(item, index) in $hightFarVisionList" :key="index" v-show="ins == index"
                v-touch:left="onSwipeLeft" v-touch:right="onSwipeRight" v-touch:down="onSwipeDown"
                v-touch:up="onSwipeUp" class="flex flex-align-items flex-justify-content">
                <div :class="
                  currentItem.class == 'type1'
                    ? 'type1'
                    : currentItem.class == 'type2'
                      ? 'type2'
                      : currentItem.class == 'type3'
                        ? 'type3'
                        : 'type4'
                ">
                  <!-- <i class='icon-E iconfont'
                    :style="{fontSize:(width*item.scale)+'px'}" ></i> -->
                  <img src="../../assets/imgs/E.png" :style="{ width: width * item.scale + 'px' }" alt="" />
                </div>
              </li>
            </div>

            <!-- 底部倒计时icon -->
            <div class="clock-box flex flex-align-items">
              <i v-show="n == 3 || n == 2 || n == 1" class="clock-circle"></i>
              <i v-show="n == 3 || n == 2" class="clock-circle"></i>
              <i v-show="n == 3" class="clock-circle"></i>
            </div>
          </ul>
          <!--  -->
        </div>
        <!-- <div class='audio-box align-left' @click="startRecord">
          <img src="../../assets/imgs/audio.png" alt="">语音识别中
        </div> -->
        <div class="flex bottom-box" style="width: calc(100% - 60px); padding-bottom: 30px">
          <div class="flex-1">
            <img src="../../assets/imgs/up-icon.png" alt="" @click="onSwipeUp" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/down-icon.png" alt="" @click="onSwipeDown" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/cancel.png" alt="" @click="cancel" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/left-icon.png" alt="" @click="onSwipeLeft" />
          </div>
          <div class="flex-1">
            <img src="../../assets/imgs/right-icon.png" alt="" @click="onSwipeRight" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from "../../components/header.vue";
import commonVision from "../../components/commonVision.vue";
import { Dialog } from "vant";
export default {
  components: {
    vHeader,
    commonVision,
  },
  data() {
    return {
      ins: 0,
      width:
        7.272 /
        (((this.$store.state.sys_param.Dpi * 1) /
          this.$store.state.sys_param.PPI) *
          2.45),
      innerIns: 0,
      n: 5,
      infoMessage: "",
      currentItem: {},
      currentCorrectCount_left: 0,
      currentCorrectCount_right: 0,
      currentErrorCount_left: 0,
      currentErrorCount_right: 0,
      isNextStep: true,
      hasError: false,
      timer: null,
      isPlay: true,
      tmpArr: [0, 1, 2, 3],
      // height:95
    };
  },
  computed: {
    playSrc() {
      if (this.$store.state.eyeText == "左眼") {
        return "/assets/Amsler/audios/1.1.mp3";
      } else {
        return "/assets/Amsler/audios/1.2.mp3";
      }
    },
  },
  mounted() {
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        console.log("WeixinJSBridgeReady");
        document.getElementById("myAudio").play(); //视频自动播放
      },
      false
    );
    if (window.name == 1) {
      window.name = 2;
      window.location.reload();
    }
    var video = document.querySelector("audio");
    document.addEventListener("visibilitychange", () => {
      // 当页面隐藏时候
      if (document.visibilityState === "visible") {
        // 视频暂停
        this.isPlay = false;
        // video.play();
      } else {
        // 当页面显示时视频开始播放
        video.pause();
      }
    });
  },
  watch: {
    result: {
      deep: true,
      handler(o) {
        if (o.indexOf("左") != -1) {
          this.onSwipeLeft();
        } else if (o.indexOf("右") != -1) {
          this.onSwipeRight();
        } else if (o.indexOf("上") != -1) {
          this.onSwipeUp();
        } else if (o.indexOf("下") != -1) {
          this.onSwipeDown();
        }
        // this.$refs['voiceRef'].start()
      },
    },
    infoMessage: {
      immediate: true,
      deep: true,
      handler(o) {
        if (o) {
          let str = this.$store.state.eyeText == "左眼" ? "右眼" : "完成";
          let c = "";
          if (this.$store.state.rightEye) {
            c = `右眼:${this.$store.state.rightEye}`;
          }
          let text = `左眼:${this.$store.state.leftEye}\r\n\r\n${c}`;
          Dialog.confirm({
            title: "您的检查结果",
            message: text,
            cancelButtonText: "重新检查",
            confirmButtonText: "检查" + str,
          })
            .then(async (action, done) => {
              if (action == "confirm") {
                if (str == "右眼") {
                  this.$store.commit(
                    "set_currentErrorCount_left",
                    this.currentErrorCount_left
                  );
                  this.$store.commit(
                    "set_currentCorrectCount_left",
                    this.currentCorrectCount_left
                  );
                }
                this.$store.commit("set_eye_text", str);
                if (this.$store.state.eyeText == "完成") {
                  let userid = await this.$vlf.getItem('userId');
                  let from = await this.$vlf.getItem('from')
                  if (from) {
                    this.$httpProxy.post(`/${from}/InsertEyes`, {
                      UserId: userid,
                      Type: 2,
                      LeftMax: this.$store.state.leftEye.split('(')[0].replace(' ', ''),
                      LeftMin: this.$store.state.leftEye.split('(')[1].replace(')', ''),
                      RightMax: this.$store.state.rightEye.split('(')[0].replace(' ', ''),
                      RightMin: this.$store.state.rightEye.split('(')[1].replace(')', ''),
                      LeftCorrectCount: this.$store.state.currentCorrectCount_left,
                      LeftErrorCount: this.$store.state.currentErrorCount_left,
                      RightErrorCount: this.currentErrorCount_right,
                      RightCorrectCount: this.currentCorrectCount_right,
                    }).catch(e => {
                      console.log(e)
                    })
                  }
                  this.$httpProxy
                    .post("/api/visual/InsertEyes", {
                      VisualId: this.$route.query.visualId,
                      Type: 2,
                      LeftMax: this.$store.state.leftEye
                        .split("(")[0]
                        .replace(" ", ""),
                      LeftMin: this.$store.state.leftEye
                        .split("(")[1]
                        .replace(")", ""),
                      RightMax: this.$store.state.rightEye
                        .split("(")[0]
                        .replace(" ", ""),
                      RightMin: this.$store.state.rightEye
                        .split("(")[1]
                        .replace(")", ""),
                      LeftCorrectCount:
                        this.$store.state.currentCorrectCount_left,
                      LeftErrorCount: this.$store.state.currentErrorCount_left,
                      RightErrorCount: this.currentErrorCount_right,
                      RightCorrectCount: this.currentCorrectCount_right,
                    })
                    .then((res) => {
                      if (res.data.Success) {
                        // this.$router.go(-2);
                        if(from){
                            this.$router.push({ name: "index",query: {from:from,userid:userid}});
                        }else{
                            this.$router.push({ name: "index"});
                        }
                      } else {
                        this.$message.warning("出错了");
                      }
                    });
                } else {
                  window.location.reload();
                }
                // this.$router.go(0);
              } else {
                done();
              }
            })
            .catch(() => {
              window.location.reload();
              // this.$router.go(0);
            });
        }
      },
    },
  },
  async created() {
    let _this = this;
    _this.n = sessionStorage.getItem("clock")
      ? sessionStorage.getItem("clock")
      : this.$time;
    if (JSON.parse(sessionStorage.getItem("isInterval"))) {
      this.timer = setInterval(() => {
        if (_this.n > 0) {
          _this.n--;
        } else {
          _this.cancel();
          _this.isNext();
        }
      }, 1000);
    }
    if (this.tmpArr.length > 0) {
      let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)];
      this.currentItem = this.$rowList[c];
      this.tmpArr.splice(this.tmpArr.indexOf(c), 1);
    } else {
      this.tmpArr = [0, 1, 2, 3];
      let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)];
      this.currentItem = this.$rowList[c];
      this.tmpArr.splice(this.tmpArr.indexOf(c), 1);
    }

    let userid = await this.$vlf.getItem("userId");
    this.$httpProxy.get("/api/visual/get", { Id: userid }).then((res) => {
      if (res.data.Success) {
        let initIni = res.data.ResData.Js;
        if (Object.keys(initIni).length > 0) {
          let i = 0,
            obj = Object.keys(initIni),
            tmp = {};
          for (let j = 0; j < obj.length; j++) {
            if (initIni[obj[j]][0].Type == 2) {
              tmp = initIni[obj[j]][0];
              break;
            }
          }
          if (Object.keys(tmp).length > 0) {
            if (this.$store.state.eyeText == "左眼") {
              i = tmp.LeftMax;
            } else {
              i = tmp.RightMax;
            }
            let c =
              this.$hightFarVisionList.findIndex((e) => e.decimalRecord == i) ||
              0;
            if (c - 1 > 0) {
              this.ins = c - 1;
            } else {
              this.ins = 0;
            }
          }
        }
      } else {
        console.log(res)
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    document.getElementById("myAudio").pause();
  },
  methods: {
    overAudio(e) {
      this.isPlay = false;
      // this.$refs.myAudio.load();
      // this.$refs.myAudio.src=this.src
      // this.$refs.myAudio.play();
    },
    onSwipeUp() {
      if (this.currentItem.correctAnswer == "top") {
        this.$hightFarVisionList[this.ins].correctCount++;
      }
      this.$hightFarVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(0, -1000);
    },
    onSwipeDown() {
      if (this.currentItem.correctAnswer == "bottom") {
        this.$hightFarVisionList[this.ins].correctCount++;
      }
      this.$hightFarVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(0, 1000);
    },
    onSwipeLeft() {
      if (this.currentItem.correctAnswer == "left") {
        this.$hightFarVisionList[this.ins].correctCount++;
      }
      this.$hightFarVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(-1000, 0);
    },
    animeCheck(left = 0, rigth = 0, next) {
      let div = document.getElementById("echart");
      div.style = `-webkit-transform: translate(${left}px,${rigth}px); transition: 1s ease 0s; `;
      setTimeout(() => {
        div.style = `-webkit-transform: translate:none; transition: 0s ease 0s;`;
        this.isNext();
      }, 1000);
    },
    onSwipeRight() {
      if (this.currentItem.correctAnswer == "right") {
        this.$hightFarVisionList[this.ins].correctCount++;
      }
      this.$hightFarVisionList[this.ins].hasAnswerCount++;
      this.animeCheck(1000, 0);
      // this.isNext();
    },
    cancel() {
      // this.$message.error('你放弃回答');
      this.$hightFarVisionList[this.ins].hasAnswerCount++;
      this.isNext();
    },
    isNext() {
      if (this.isNextStep) {
        this.n = this.$time;
        if (
          this.$hightFarVisionList[this.ins].hasAnswerCount <=
          this.$hightFarVisionList[this.ins].count - 1
        ) {
          if (this.tmpArr.length > 0) {
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)];
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1);
            this.currentItem = this.$rowList[c];
          } else {
            this.tmpArr = [0, 1, 2, 3];
            let c = this.tmpArr[Math.floor(Math.random() * this.tmpArr.length)];
            this.tmpArr.splice(this.tmpArr.indexOf(c), 1);
            this.currentItem = this.$rowList[c];
          }
        } else {
          // 如果全对跳3行，错一个并且大于最少正确量跳至下一个
          if (
            this.$hightFarVisionList[this.ins].correctCount >=
            this.$hightFarVisionList[this.ins].minCorrectCount
          ) {
            if (this.ins == this.$hightFarVisionList.length - 1) {
              // this.ins++;
              this.isNextStep = false;
              this.infoMessage =
                "你的视力为" +
                this.$hightFarVisionList[this.ins].fivePointsRecord;
              this.setCount(this.ins);
              this.setEyeVal(this.ins);
              clearInterval(this.timer);
              return;
            } else {
              if (this.$hightFarVisionList[this.ins+1].hasAnswerCount) {
                this.isNextStep = false;
                this.infoMessage =
                  "你的视力为" +
                  this.$hightFarVisionList[this.ins].fivePointsRecord +
                  "正确数量为：" +
                  this.$hightFarVisionList[this.ins].correctCount +
                  "答题数量为：" +
                  this.$hightFarVisionList[this.ins].hasAnswerCount;
                this.setCount(this.ins);
                this.setEyeVal(this.ins);
              } else {
                this.ins++;
              }
            }
          } else {
            // // 如果正确数量小于最少正确量，往前跳一级
            this.hasError = true;
            // 判断上一级是否答过
            if (this.ins >= 1) {
              if (
                this.$hightFarVisionList[this.ins - 1].correctCount >=
                this.$hightFarVisionList[this.ins - 1].minCorrectCount
              ) {
                this.isNextStep = false;
                this.infoMessage =
                  "你的视力为" +
                  this.$hightFarVisionList[this.ins - 1].fivePointsRecord +
                  "正确数量为：" +
                  this.$hightFarVisionList[this.ins - 1].correctCount +
                  "答题数量为：" +
                  this.$hightFarVisionList[this.ins - 1].hasAnswerCount;
                this.setCount(this.ins - 1);
                this.setEyeVal(this.ins - 1);
                clearInterval(this.timer);
              } else {
                this.ins--;
              }
            } else {
              this.infoMessage =
                "你的视力为" +
                this.$hightFarVisionList[0].fivePointsRecord +
                "正确数量为：" +
                this.$hightFarVisionList[0].correctCount +
                "答题数量为：" +
                this.$hightFarVisionList[0].hasAnswerCount;
              this.setEyeVal(0);
              clearInterval(this.timer);
            }
          }
        }
      }
    },
    setCount(o) {
      if (this.$store.state.eyeText == "左眼") {
        this.currentCorrectCount_left =
          this.$hightFarVisionList[o].correctCount;
        this.currentErrorCount_left =
          parseInt(this.$hightFarVisionList[o].hasAnswerCount) -
          this.$hightFarVisionList[o].correctCount;
      } else {
        this.currentCorrectCount_right =
          this.$hightFarVisionList[o].correctCount;
        this.currentErrorCount_right =
          parseInt(this.$hightFarVisionList[o].hasAnswerCount) -
          this.$hightFarVisionList[o].correctCount;
      }
    },
    setEyeVal(rowIndex) {
      if (this.$store.state.eyeText == "左眼") {
        this.$store.commit(
          "set_left_eye",
          `${this.$hightFarVisionList[rowIndex].decimalRecord.toFixed(
            2
          )} (${this.$hightFarVisionList[rowIndex].fivePointsRecord.toFixed(
            1
          )})`
        );
      } else {
        this.$store.commit(
          "set_right_eye",
          `${this.$hightFarVisionList[rowIndex].decimalRecord.toFixed(
            2
          )} (${this.$hightFarVisionList[rowIndex].fivePointsRecord.toFixed(
            1
          )})`
        );
      }
    },
  },
};
</script>


 
<style lang="less">
@import "../../assets/less/vision.less";

.divc {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>