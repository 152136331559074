var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box vision-box"},[_c('div',{directives:[{name:"wechat-title",rawName:"v-wechat-title",value:('高度近视检查'),expression:"'高度近视检查'"}]}),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"vision-box-content"},[_c('div',{staticClass:"flex flex-align-items vision-title-block",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('audio',{ref:"myAudio",attrs:{"id":"myAudio","hidden":"true","controls":""},on:{"ended":_vm.overAudio}},[_c('source',{attrs:{"src":_vm.playSrc,"type":"audio/mpeg"}})]),(_vm.isPlay)?_c('div',{staticClass:"mountain"}):_vm._e()]),_c('div',[_c('img',{attrs:{"src":require("../../assets/imgs/eye.png"),"alt":""}}),_vm._v(_vm._s(_vm.$store.state.eyeText)+" ")]),_c('div',{staticClass:"align-right"})]),_c('div',{staticClass:"vision-content"},[_c('ul',{staticClass:"list"},[_c('div',{staticClass:"divc",attrs:{"id":"echart"}},_vm._l((_vm.$hightFarVisionList),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.ins == index),expression:"ins == index"},{name:"touch",rawName:"v-touch:left",value:(_vm.onSwipeLeft),expression:"onSwipeLeft",arg:"left"},{name:"touch",rawName:"v-touch:right",value:(_vm.onSwipeRight),expression:"onSwipeRight",arg:"right"},{name:"touch",rawName:"v-touch:down",value:(_vm.onSwipeDown),expression:"onSwipeDown",arg:"down"},{name:"touch",rawName:"v-touch:up",value:(_vm.onSwipeUp),expression:"onSwipeUp",arg:"up"}],key:index,staticClass:"flex flex-align-items flex-justify-content"},[_c('div',{class:_vm.currentItem.class == 'type1'
                  ? 'type1'
                  : _vm.currentItem.class == 'type2'
                    ? 'type2'
                    : _vm.currentItem.class == 'type3'
                      ? 'type3'
                      : 'type4'},[_c('img',{style:({ width: _vm.width * item.scale + 'px' }),attrs:{"src":require("../../assets/imgs/E.png"),"alt":""}})])])}),0),_c('div',{staticClass:"clock-box flex flex-align-items"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.n == 3 || _vm.n == 2 || _vm.n == 1),expression:"n == 3 || n == 2 || n == 1"}],staticClass:"clock-circle"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.n == 3 || _vm.n == 2),expression:"n == 3 || n == 2"}],staticClass:"clock-circle"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.n == 3),expression:"n == 3"}],staticClass:"clock-circle"})])])]),_c('div',{staticClass:"flex bottom-box",staticStyle:{"width":"calc(100% - 60px)","padding-bottom":"30px"}},[_c('div',{staticClass:"flex-1"},[_c('img',{attrs:{"src":require("../../assets/imgs/up-icon.png"),"alt":""},on:{"click":_vm.onSwipeUp}})]),_c('div',{staticClass:"flex-1"},[_c('img',{attrs:{"src":require("../../assets/imgs/down-icon.png"),"alt":""},on:{"click":_vm.onSwipeDown}})]),_c('div',{staticClass:"flex-1"},[_c('img',{attrs:{"src":require("../../assets/imgs/cancel.png"),"alt":""},on:{"click":_vm.cancel}})]),_c('div',{staticClass:"flex-1"},[_c('img',{attrs:{"src":require("../../assets/imgs/left-icon.png"),"alt":""},on:{"click":_vm.onSwipeLeft}})]),_c('div',{staticClass:"flex-1"},[_c('img',{attrs:{"src":require("../../assets/imgs/right-icon.png"),"alt":""},on:{"click":_vm.onSwipeRight}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }